.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: blue;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-grid-item:not(.react-grid-placeholder) {
  background: whitesmoke;
}

.window-header {
  background-color: lightblue;
  display: flex;
  justify-content: space-between;
  height: 20px;
}

.app-logo {
  display: flex;
}

.app-logo-icon {
  height: 20px;
}

.app-name {
  font-size: 14px;
  padding-left: 8px;
}

.actions-container {
  display: flex;
}

.window-button-style {
  background-color: transparent;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  transition: background-color 150ms ease;
}

.window-controls-icon {
  fill: #958fb2;
  pointer-events: none;
  user-select: none;
  width: 20px;
  height: 20px;
  transition: fill 150ms ease;
}

.window-controls-icon:hover {
  background-color: rgb(77, 46, 46);
}

.window-controls-icon {
  fill: #f4f2ff;
}

.settings-window:hover {
  background-color: orange;
}

.close-window:hover {
  background-color: #e6004c;
}

.close-window::focus {
  outline: none;
}

/* assign dashboard */
.marginTop {
  margin-top: 40px;
}
.bg-layout {
  background-color: #e3e2e7;
}

.reportTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.reportTable td,
.reportTable th {
  border: 1px solid black !important;
  padding: 8px;
  text-align: right;
}
#border-top-0 {
  border-top: 0px !important;
}
.reportTable th {
  background-color: #FBE4D5;
  text-align: center;
}
.border-top-0,
.border-top-0 th {
  border: 0;
}

.border-0,
.border-0 th {
  border: 0;
}
.ant-picker-input > input::placeholder {
  color: black !important;
}

.qrCode_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
}
.qrCode:hover .qrCode_overlay {
  visibility: visible;
}

.apexcharts-menu-icon,
.apexcharts-toolbar {
  display: none !important;
}

.fullscreen.fullscreen-enabled {
  overflow: hidden;
  overflow-y: scroll;
  background-color: #fff;
}

/* .custom-table thead th, .antd-table-custom-class tbody 
 td
 {    white-space: nowrap;
      word-break:break-word;
      word-break: break-all;
      white-space:pre-wrap;
      text-overflow: ellipsis
 } */

/* .ant-table td { white-space: nowrap; } */

/* FOR RESPONSIVE TABLE */
.ant-table-scroll-horizontal {
  max-width: calc(100vw - 340px);
  /* max-width: 78vw; */
}
@media screen and (max-width: 992px) {
  .ant-table-scroll-horizontal {
    max-width: none;
  }
}
body {
  overflow-x: hidden;
}
/* RESPONSIVE TABLE END */
.select__indicator svg,
.ant-picker-suffix {
  color: #727070 !important;
}
.select__placeholder,
.ant-select-selection-placeholder {
  color: #737374 !important;
}
.ant-input::placeholder {
  color: #737374 !important;
}
.footer_link {
  color: #fff !important;
}
