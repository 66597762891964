.leaflet-container {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 50;
}

a {
  color: #000000 !important;
  text-decoration: none !important;
}
.btn {
  color: #ffffff !important;
}
.az-content-wrapper {
  background-size: cover;
}
.az-signin-header label {
  color: #000000;
}

.az-card-signin {
  background-color: rgb(255 255 255 / 80%) !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.swal2-styled.swal2-confirm {
  background-color: #d90f00 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel:focus {
  box-shadow: none !important;
}

.az-logos {
  width: 100px !important;
}

.az-header-center {
  margin: 0px !important;
}
.dropdown-menu.show {
  min-width: 225px !important;
}
.dropdown-item {
  padding: 3px 13px !important;
  font-size: 15px;
}
.az-sidebar-body .nav-item {
  cursor: pointer;
}
.description p {
  margin: 0px !important;
}
.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  font-size: 15px !important;
}
.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 14px !important;
}

.addDeviceBtn {
  margin-left: 491px;
}

.checkbox {
  margin-bottom: 15px;
  margin-left: 20px;
}

/* .footer{
    margin-top: 200px;
  } */

.clickbtn {
  cursor: pointer;
}

/* body{
    overflow-x: hidden;
  } */

.graphShadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* .progress-bar{
    height: 100% !important;
  } */

/* .ant-table-body { overflow-x: auto !important}  */
.ant-table-cell {
  white-space: nowrap;
}
.disable_area a {
  cursor: not-allowed !important;
}
.disable_area {
  pointer-events: none;
  cursor: not-allowed !important;
  background-color: rgba(25, 135, 84, 0.7) !important;
}

.centered_input input {
  text-align: center !important;
}
